import React, { Component} from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Twitter, Github, Linkedin, HouseDoor, Award, FilePerson, PersonWorkspace, EnvelopeAt  } from 'react-bootstrap-icons';
import Stack from 'react-bootstrap/Stack';

function FooterMenu() {
    return (
        <>
            <Nav.Link href="https://twitter.com/yeanperuramesh" target="_blank"><Twitter /></Nav.Link>
            <Nav.Link href="https://github.com/ramkrk" target="_blank"><Github /></Nav.Link>
            <Nav.Link href="https://www.linkedin.com/in/rameshkumar-muniyammal/" target="_blank"><Linkedin /></Nav.Link>
        </>
    )
}


function OffcanvasMenuFooter({...props}) {
    return (
        <>
            <Stack direction="horizontal" gap={3}>
                <FooterMenu key="OffcanvasMenuFooterKey"/>
            </Stack>
        </>
    )
}

function Contact({...props}) {
    return (
        <Nav className={props.classNames}>
            <div><Nav.Link href="phone:+91 95005 74445">+91 95005 74445</Nav.Link></div>
            <div><Nav.Link href="mailto:krameshkumar90@gmail.com">krameshkumar90@gmail.com</Nav.Link></div>
        </Nav>
    )
}

function OffCanvasMenu({ ...props }) {
    return (
      <>
        <Nav>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${props.expand}`} />
            <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${props.expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${props.expand}`}
            placement="top"
            >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${props.expand}`}>
                    <OffcanvasMenuFooter />
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="profile-main-menu d-flex">
                    <Nav className="justify-content-start flex-grow-1 pe-3">
                    {props.menuList.map((items) => (
                        <div key={`menu-item-${items.id}`}>
                            <Nav.Link className={
                                ('#'+ props.activeAnchor === items.link ? "visible" : "") 
                            } key={items.id} href={items.link}>
                                <span className="d-none d-md-flex">{items.label}</span><span className="d-sm-none">{items.icon}</span>
                            </Nav.Link>
                        </div>
                    ))}
                    </Nav>
                </div>
                <div className="profile-contact-menu d-flex">
                    <Contact key="OffcanvasMenuContactKey" classNames="d-sm-none d-lg-none d-md-flex me-auto"/>
                </div>
            </Offcanvas.Body>
            </Navbar.Offcanvas>
        </Nav>
      </>
    );
  }

class ProfileMenu extends Component<any, any> {
    menuList = [
        { id: 'menu_item_1', label: 'Home', link: '#home', icon: <HouseDoor size={30}/> },
        { id: 'menu_item_2', label: 'Expertise', link: '#about', icon: <Award size={30}/> },
        { id: 'menu_item_3', label: 'Resume', link: '#resume', icon: <FilePerson size={30}/> },
        { id: 'menu_item_4', label: 'Projects', link: '#projects', icon: <PersonWorkspace size={30}/> },
        { id: 'menu_item_5', label: 'Contact', link: '#contact', icon: <EnvelopeAt size={30}/> },
    ]

    render() {
      return  <OffCanvasMenu activeAnchor={this.props.activeAnchor} menuList={this.menuList} expand="lg"/>
    }
}

export{ 
    ProfileMenu,
    Contact,
    FooterMenu
};
